import http from '@/api/http'

export default {
  articleGet (id: string | number) {
    return http.get('/uapi/article/md/detail', {id})
  },
  articleList (params: object) {
    return http.get('/uapi/article/md/list', params)
  }
}
